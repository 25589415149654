<template>
  <div class="mt-5 info-card-text-click">
    <h3><span class="color-red info-arrow"> ➔</span> Proteja sua conta</h3>
    <p>
      Altere sua senha para a conta de login afetada. Isso
      impedirá que hackers acessem sua conta e roubem mais
      informações sobre você.
    </p>
    <p>
      Com a McAfee, você terá acesso a informações
      detalhadas para ajudá-lo a determinar qual conta está em
      risco.
    </p>
    <h3><span class="color-red info-arrow"> ➔</span> Use senhas únicas e fortes</h3>
    <p>
      Um ladrão de identidades geralmente usa informações de
      login roubadas de uma violação para tentar acessar outras
      contas que podem ter a mesma combinação de e-mail e senha.
    </p>
    <p>
      Use um gerenciador de senhas seguro para gerar e armazenar
      senhas exclusivas para cada site em que você possui uma
      conta. A McAfee pode ajudá-lo com nosso gerenciador de
      senhas criptografadas, sincronizado on-line em vários
      dispositivos para sua conveniência.
    </p>
    <h3><span class="color-red info-arrow"> ➔</span> Fique de olho em violações adicionais</h3>
    <p>
      As violações ocorrem quando empresas que possuem
      vulnerabilidades de segurança são exploradas por hackers.
    </p>
    <p>
      A McAfee pode ajudá-lo a ficar à frente do roubo de
      identidade, detectando e alertando você sobre essas
      violações com uma média de 10 meses de antecedência em
      relação a serviços semelhantes, para lhe dar uma chance de
      evitar totalmente o roubo de identidade.
    </p>
  </div>
</template>
<script>
export default {
  name: 'AdditionalInfo',
}
</script>
<style scoped>

b {
  font-weight: 600;
}

h2 {
  font-size: 30px;
}

h3 {
  font-size: 20px;
}

.color-red {
  color: red;
}

</style>
