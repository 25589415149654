<template>
  <div class="row m-0" :class="{'design-in-modal': props.inModal, 'design-out-of-modal': !props.inModal}">
    <div class="col col-12 col-md-4 p-0">
      <Image
        :in-modal="inModal"
        :is-mobile="isMobile"
        desktop-image="woman_desktop_image"
        mobile-image="woman_mobile_image"
        alt="woman concerned"
      />
    </div>

    <div class="col col-12 col-md-8 d-flex flex-column justify-content-center py-5 py-md-2">
      <Form class="w-75 mx-auto" @submit="submit" :initialValues="initialValues" :validationSchema="validationSchema">

        <h6 class="display-6">Sabia que seu e-mail pode ter vazado na <b>Dark Web</b> e estar nas mãos de criminosos?</h6>
        <p class="mt-3 mb-0">A cada 1,12 segundos acontece um roubo de dados na internet.</p>
        <p>Veja se seus dados foram vazados e <b>PROTEJA-SE AGORA</b>!</p>

        <div class="mt-4">
          <Field name="email" type="email" placeholder="Insira o endereço de e-mail">
            <template v-slot:beforeInput>
              <span class="input-group-text">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="icon p-0">
                  <path stroke-linecap="round" stroke-linejoin="round" d="M21.75 6.75v10.5a2.25 2.25 0 0 1-2.25 2.25h-15a2.25 2.25 0 0 1-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0 0 19.5 4.5h-15a2.25 2.25 0 0 0-2.25 2.25m19.5 0v.243a2.25 2.25 0 0 1-1.07 1.916l-7.5 4.615a2.25 2.25 0 0 1-2.36 0L3.32 8.91a2.25 2.25 0 0 1-1.07-1.916V6.75" />
                </svg>
              </span>
            </template>
          </Field>

          <div class="mb-2">
            <p class="mt-2 text-secondary fw-light w-100">
              <strong class="text-dark">Nunca</strong> iremos guardar, vender ou enviar mensagens para esse e-mail.
            </p>
          </div>

          <button type="submit" :disabled="isLoading" :class="'btn btn-danger rounded-pill '.concat(inModal ? 'w-100' : 'w-50')">
            <b v-if="!isLoading">Verificar</b>
            <b v-else>Verificando...</b>
          </button>
        </div>

        <nav class="mt-4 text-justify text-secondary fw-normal">
          Ao clicar em “Verificar”, você aceita os
          <a :href="termLink" target="_blank" class="text-dark fw-bold">termos de licenciamento McAfee</a>, o
          <a :href="privacyLink" target="_blank" class="text-dark fw-bold">Aviso de Privacidade</a>
          e confirma que tem mais de 16 anos e é o dono do e-mail informado.
        </nav>
      </Form>
    </div>
  </div>
</template>

<script setup>
  import { defineProps, defineEmits } from "vue";

  import useScan from "./hooks/useScan";

  const {
    Form,
    Field,
    initialValues,
    validationSchema,
    Image,
    submit,
    emitter
  } = useScan();

  const emit = defineEmits(['verify-breach']);

  emitter.value = emit

  const props = defineProps({
    privacyLink: String,
    termLink: String,
    isMobile: Boolean,
    isLoading: Boolean,
    inModal: Boolean,
  });
</script>

<style scoped>

  .design-out-of-modal .col:first-child {
    display: flex;
    justify-content: center;
  }

  .design-out-of-modal .col:first-child img {
    border-radius: 30px;
    max-height: 500px;
  }

  .design-in-modal .col:first-child img {
    width: 100%;
    height: 100%;
    border-top-left-radius: 30px;
    border-top-right-radius: 30px;
    object-fit: cover;
  }

  @media screen and (min-width: 768px) {
    .design-in-modal .col:first-child img {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 30px;
    }
  }

  .icon {
    width: 25px;
    height: 25px;
  }
</style>
