<template>
  <img :src="imgLink"/>
</template>

<script>
export default {
  name: "Img",
  props: {
    name: {
      required: true,
    }
  },
  computed: {
    imgLink () {
      return require(`@/assets/img/${this.name}.png`)
    }
  }
}
</script>

<style scoped>

</style>