<template>
    <div :class="props.inputGroupClass">
        <slot name="beforeInput"></slot>
        <Field
            :name="props.name"
            :type="props.type"
            :class="props.inputClass"
            :as="props.as"
            :placeholder="props.placeholder">
                <slot></slot>
        </Field>
    </div>
    <ErrorMessage
        :class="props.errorMessageClass"
        :name="props.name"
        :as="props.errorMessageAs"
    />
</template>

<script setup>
    import { Field, ErrorMessage } from "vee-validate";
    import { defineProps } from "vue";

    const props = defineProps({
        name: {
            type: String,
            required: true
        },
        type: {
            type: String,
            required: false,
            default: "text"
        },
        as: {
            type: String,
            required: false,
            default: "input"
        },
        placeholder:{
            type: String,
            required: false,
            default: ""
        },
        inputClass: {
            type: String,
            required: false,
            default: "form-control"
        },
        errorMessageAs: {
            type: String,
            required: false,
            default: "span"
        },
        errorMessageClass: {
            type: String,
            required: false,
            default: "text-danger mt-2 d-block"
        },
        inputGroupClass: {
            type: String,
            required: false,
            default: "input-group"
        }
    })
</script>
