import Field from "@/components/Form/Input/Field";
import { Form } from "vee-validate";
import Image from "./../Image.vue";
import { initialValues, validationSchema } from "./../schemas/idScanSchema";
import { ref } from "vue";

export default function useScan() {
  const emitter = ref();

  function submit({ email }) {
    emitter?.value("verify-breach", email);
  }

  return {
    Form,
    Field,
    initialValues,
    validationSchema,
    Image,
    emitter,
    submit
  };
}
