import * as yup from "yup";

export const initialValues = {
  email: "",
};

export const validationSchema = yup.object().shape({
  email: yup
    .string("Este campo deves ser um texto")
    .required("Este campo é obrigatório")
    .min(9, 'Este e-mail ainda  não parece válido')
    .email("E-mail inválido! Por favor, revise seu e-mail"),
});
