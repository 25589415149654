<template>
    <div class="row m-0" :class="{'design-in-modal': inModal, 'design-out-of-modal': !inModal}">
      <div class="col col-12 col-md-4 p-0">
        <Image
            :in-modal="inModal"
            :is-mobile="isMobile"
            desktop-image="desktop_success_good_news"
            mobile-image="mobile_success"
            alt="Homem comemorando"
        />
      </div>

      <div class="col col-12 col-md-8 d-flex flex-column justify-content-center py-5 py-md-2">
        <div class="p-3">
            <h2>{{ email || 'Seu email' }} foi encontrado em <b class="text-danger">{{ breachCount || 0}}</b> vazamentos de dados conhecidos.</h2>
            <p><b>Ótimas notícias!</b> O seu e-mail ainda não foi encontrado em um vazamento de dados. Veja as nossas soluções para se proteger mais!</p>
            <p><b>Você tem muita sorte!</b> Mais de 40 bilhões de registros foram expostos em 2021</p>

            <nav>
                <p class="m-0">O que você deve fazer se o seu e-mail não faz parte de um vazamento?</p>
                <a @click.prevent="() => showAdditionalInfo = !showAdditionalInfo" href="#" class="fw-bold text-dark">
                    Clique aqui para mais informações.
                </a>
            </nav>

            <nav class="text-center mt-3">
                <a href="#" @click.prevent="backToForm" class="fw-bold text-danger">Verificar outro e-mail</a>
            </nav>

            <AdditionalInfo v-if="showAdditionalInfo"/>
        </div>
      </div>
    </div>
</template>

<script>
import AdditionalInfo from "./AdditionalInfo.vue";
import Image from "./Image.vue";

export default {
  name: 'GoodNewsResponse',
  components: {
    Image,
    AdditionalInfo,
  },
  props: {
    email: String,
    breachCount: Number,
    isMobile: Boolean,
    inModal: Boolean,
  },
  data() {
    return {
      showAdditionalInfo: false
    }
  },
  methods: {
    backToForm() {
      this.$emit('backToForm');
    }
  }
}
</script>

<style scoped>
  .design-out-of-modal .col:first-child {
    display: flex;
    justify-content: center;
  }

  .design-out-of-modal .col:first-child img {
    border-radius: 30px;
    max-height: 500px;
  }

  .design-in-modal .col:first-child img {
    width: 100%;
    height: 100%;
    border-top-left-radius: 30px;
    border-top-right-radius: 30px;
    object-fit: cover;
  }

  @media screen and (min-width: 768px) {
    .design-in-modal .col:first-child img {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 30px;
    }
  }
</style>
