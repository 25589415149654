<template>
  <div class="modal fade" id="DarkWebModal" tabindex="-1" aria-labelledby="DarkWebModalLabel" aria-hidden="true" data-bs-backdrop="static">
    <div class="modal-dialog modal-dialog-centered modal-xl">
      <div class="modal-content position-relative">
        <button id="closeModalButton" class="btn position-absolute fs-4" @click="closeModal" type="button">
          <i class="fas fa-times"></i>
        </button>

        <div class="modal-body p-0">
          <IdScan in-modal />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Modal } from 'bootstrap';
import IdScan from './IdScan.vue';

export default {
  data() {
    return {
      modal: null
    };
  },
  components:{
    IdScan
  },

  mounted() {
    this.modal = new Modal(document.querySelector('#DarkWebModal'))

    this.openModal()
  },

  methods: {
    openModal() {
      this.modal.show()
    },

    closeModal() {
      this.modal.hide()
    }
  }
};
</script>

<style scoped>
  .modal-content {
    border-radius: 30px;
  }

  #closeModalButton {
    top: 1rem;
    right: 1rem;
    z-index: 10;
  }
</style>
