<template>
    <div class="row m-0" :class="{ 'design-in-modal': inModal, 'design-out-of-modal': !inModal }">
        <div class="col col-12 col-md-4 p-0">
            <Image
                :in-modal="inModal"
                :is-mobile="isMobile"
                desktop-image="man_desktop_image"
                mobile-image="man_mobile_image"
                alt="man very worried"
            />
        </div>

        <div v-if="!error" class="col col-12 col-md-8 d-flex flex-column justify-content-center py-5 py-md-2">
            <h2>{{ email || 'Seu email' }} foi encontrado em <b class="text-danger">{{ breachCount || 0 }}</b> vazamentos de dados conhecidos.</h2>
            <p class="my-3">Não se preocupe, <b>você não está sozinho(a)</b>. Veja nossas soluções para se manter protegido.</p>
            <nav>
                O que você deve fazer caso o seu e-mail faça parte de um vazamento de dados?
                <a @click.prevent="() => showAdditionalInfo = !showAdditionalInfo" href="#" class="fw-bold text-dark">Clique aqui para mais informações.</a>
            </nav>

            <AdditionalInfo v-if="showAdditionalInfo" />
        </div>

        <div v-else class="col col-12 col-md-8 d-flex flex-column justify-content-center py-5 py-md-2">
            <h2>Ops! Houve um erro ao verificar o e-mail {{ email }}</h2>
            <p class="m-0">Por favor, tente novamente mais tarde.</p>
        </div>
    </div>
</template>

<script>
  import AdditionalInfo from './AdditionalInfo';
  import Image from './Image.vue';

  export default {
    name: 'BadNewsResponse',
    components: {
      Image,
      AdditionalInfo,
    },
    props: {
      error: String,
      email: String,
      breachCount: Number,
      isMobile: Boolean,
      inModal: Boolean,
    },
    data() {
      return {
        showAdditionalInfo: false
      }
    },
  };
</script>

<style scoped>
  .design-out-of-modal .col:first-child {
    display: flex;
    justify-content: center;
  }

  .design-out-of-modal .col:first-child img {
    border-radius: 30px;
    max-height: 500px;
  }

  .design-in-modal .col:first-child img {
    width: 100%;
    height: 100%;
    border-top-left-radius: 30px;
    border-top-right-radius: 30px;
    object-fit: cover;
  }

  @media screen and (min-width: 768px) {
    .design-in-modal .col:first-child img {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 30px;
    }
  }
</style>
