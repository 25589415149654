<template>
  <Img :name="isMobile ? mobileImage : desktopImage" :alt="alt" />
</template>

<script>
  import Img from "@/components/Img/Img";

  export default {
    name: "Image",
    components: {
      Img
    },
    props: {
      alt: String,
      desktopImage: String,
      mobileImage: String,
      inModal: Boolean,
      isMobile: Boolean,
    },
  };
</script>
