<template>
  <div>
    <HomeContainer>
      <template v-slot:header>
        <Header :hideIcon="true" :logoRedirectUrl="'idscan'"/>
      </template>
      <template v-slot:main>
        <HomePageDarkWeb :title="pageTitle" />
        <ModalDark />
      </template>
      <template v-slot:footer>
        <Footer />
      </template>      
    </HomeContainer>
  </div>
</template>
<script>
import Footer from "@/components/Footer/Footer.vue";
import HomePageDarkWeb from "@/components/Homepages/mcafeeDarkWeb/HomePageDarkWeb.vue";
import Header from "@/components/Header/Header.vue";
import HomeContainer from "@/components/Container/HomeContainer.vue";
import ModalDark from "@/components/Homepages/mcafeeDarkWeb/ModalDark.vue";

export default {
  name: "Home",
  components: {
    Header,
    HomePageDarkWeb,
    Footer,
    HomeContainer,
    ModalDark
  },
   data() {
    return {
      pageTitle: 'Bem vindo a página da Mcafee Dark Web'
    };
  }
};
</script>