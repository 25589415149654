<template>
  <div :class="{'mt-5': !inModal}">
    <IdScanForm
        v-if="showForm"
        :in-modal="inModal"
        :privacy-link="Configuration.mcafee.darkWeb.privacy || ''"
        :term-link="Configuration.mcafee.darkWeb.term || ''"
        @verifyBreach="verifyBreach"
        :is-mobile="isMobile"
        :is-loading="isLoading"
    />
    <BadNewsResponse
        v-if="showBad"
        :in-modal="inModal"
        :email="email"
        :error="error"
        :breach-count="getBreachCount"
        :is-mobile="isMobile"
        :show-additional-info="showAdditionalInfo"
    />
    <GoodNewsResponse
        v-if="showGood"
        :in-modal="inModal"
        @backToForm="showScanForm"
        :email="email"
        :breach-count="getBreachCount"
        :is-mobile="isMobile"
        :show-additional-info="showAdditionalInfo"
    />
  </div>
</template>

<script>
import Configuration from "@/services/configuration";
import {mapActions, mapGetters} from "vuex";
import IdScanForm from "./IdScanForm.vue";
import BadNewsResponse from "./BadNewsResponse.vue";
import GoodNewsResponse from "./GoodNewsResponse.vue";

export default {
  name: "IdScan",
  props: {
    inModal: Boolean,
  },
  data() {
    return {
      isMobile: false,
      showForm: true,
      showBad: false,
      showGood: false,
      isLoading: false,
      showAdditionalInfo: false,
      Configuration: Configuration,
      email: '',
      error: null,
    };
  },
  components:{
    BadNewsResponse,
    GoodNewsResponse,
    IdScanForm,
  },
  computed:{
    ...mapGetters({
      getBreachCount: 'mcafee/getBreachCount'
    }),
  },
  mounted() {
    this.checkMobile();
    window.addEventListener("resize", this.checkMobile);
  },
  beforeUnmount() {
    window.removeEventListener("resize", this.checkMobile);
  },
  methods: {
    ...mapActions({
      verifyEmailBreach: 'mcafee/verifyEmailBreach',
    }),
    checkMobile() {
      this.isMobile = window.innerWidth <= 768;
    },
    showScanForm() {
      this.showBad = this.showGood = false;

      this.showForm = true;
      this.email = '';
    },
    showBadNews(err) {
      this.error = err;
      this.showForm = this.showGood = false;

      this.showBad = true;
    },
    showGoodNews() {
      this.showForm = this.showBad = false;

      this.showGood = true;
    },
    verifyBreach(email) {
      if (!email) return
      this.isLoading = true
      this.email = email
      this.verifyEmailBreach(email)
          .then((bCount) => bCount === 0 ? this.showGoodNews() : this.showBadNews())
          .catch(err => this.showBadNews(err))
          .finally(() => this.isLoading = false)
    },
  }
};
</script>
